body {
  background-color: #282c34;
  min-width: 1200px;
}

.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  min-width: 100%;
  position: relative;
  font-family: 'Signika', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

p {
  font-family: 'Signika', sans-serif;
}

.App-logo {
  height: 54px;
  pointer-events: none;
}

.App-logo-title {
  font-size: 1.5em;
  font-weight: bold;
  margin-left: 12px;
  color: white;
  text-decoration: none;
}

.App-header {
  background-color: #282c34;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-left: 36px;
  padding-right: 36px;
  padding-bottom: 0px;
  color: white;
}

.row-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.row-group-together {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 20px;
  padding: 36px;
}

.App-link {
  color: white;
}

.main-content {
  height: 100%;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #282c34;
  color: white;
  align-items: center;
}

.footer {
  /* width: 100%; */
  background-color: #282c34;
}

.footer-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 36px;
  color: white;
  min-width: 1000px;
  overflow-y: scroll;
}

.prompt-form {
  margin: 0 auto;
  padding: 20px;
  text-align: left;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1200px;
  overflow-y: scroll;

  @media (min-width:1400px) {
      grid-template-columns: repeat(11, 1fr);
      width: 1400px;
  }
}

.prompt-input {
  width: 100%;
  padding: 14px;
  margin: 10px 0;
  border-radius: 4px;
  box-sizing: border-box;
  margin-right: 20px;
  font-size: large;
}

.prompt-input:disabled {
  background-color: #f2f2f2;
}

.prompt-button {
  width: 200px;
  padding: 14px;
  margin: 10px 0;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: #4CAF50;
  color: white;
  font-size: large;
}

.privacy-content {
  text-align: left;
  padding-right: 36px;
  padding-left: 36px;
  padding-top: 0px;
  padding-bottom: 36px;
  color: white;
}

.left-align {
  text-align: left;
}

.right-align {
  text-align: right;
}

.hide-link {
  text-decoration: none;
}

.title {
  font-size: 1.4em;
  font-weight: bold;
  color: white;
  text-decoration: none;
}

.copy-to-clipboard-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  background-color: #e7e7e7;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 18px;
  padding-right: 14px;
  border-radius: 8px;
}

.copy-button {
  padding: 14px;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: #4CAF50;
  color: white;
  font-size: large;
}

.share-link-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: #4CAF50;
  color: white;
  font-size: large;
  cursor: pointer;
}

.button-icon {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}

.small-link {
  font-size: 0.8em;
  overflow: hidden;
}